<script setup lang="ts">
import { useOffersStore } from '~/store/offers'
import UserName from '~/components/users/UserName.vue'
import CardOffer from '~/components/offer/CardOffer.vue'
import HomeFilters from '~/components/home/HomeFilters.vue'
import SkeletonCardOffer from '~/components/offer/SkeletonCardOffer.vue'
import { useSubscriptionStore } from '~/store/subscription'

const EmptyStateOffers = defineAsyncComponent(() => import('~/components/home/empty/EmptyStateOffers.vue'))
const CardOfferAdd = defineAsyncComponent(() => import('~/components/offer/CardOfferAdd.vue'))
const CardAvailableOffers = defineAsyncComponent(() => import('~/components/offer/CardAvailableOffers.vue'))
const CardSchedule = defineAsyncComponent(() => import('~/components/offer/CardSchedule.vue'))

const { fetchOffers } = useOffersStore()
const subscriptionStore = useSubscriptionStore()
const { offers } = storeToRefs(useOffersStore())
const router = useRouter()
const route = useRoute()
const { t: $t } = useI18n()
const { $auth } = useNuxtApp()

const loadingOffers = ref(true)

const canCreateOffer = computed<boolean>(() => {
	return $auth.user.business.rules.can_create_work_offers
})

const isShowCardAvailableOffers = computed(() => {
	return subscriptionStore.slots.filter((slot) => slot.qty_available > 0).length
})

const isShowEmptyStateOffers = computed(() => {
	return !loadingOffers.value && offers.value.data.length === 0 && !route.query.status_slugs
})

const fetchData = async (params) => {
	loadingOffers.value = true
	const { status_slugs } = params

	if (status_slugs) {
		params.status_slugs = Array.isArray(status_slugs) ? status_slugs : [status_slugs]
	}

	await fetchOffers(params)
	loadingOffers.value = false
}

const changePage = (page) => {
	const query = { ...route.query, page }
	router.push({ query })
}

watch(
	() => route.query,
	(newQuery, oldQuery) => {
		if (newQuery.page !== oldQuery.page || newQuery.status_slugs !== oldQuery.status_slugs) {
			fetchData(newQuery)
		}
	},
)

onMounted(() => {
	fetchData(route.query)
})
</script>

<template>
	<EmptyStateOffers v-if="isShowEmptyStateOffers" />

	<UContainer v-else>
		<UserName
			class="text-xl font-bold mb-6"
			:prefix="$t('Bienvenido')"
			icon="👋"
			icon-position="after"
			:full-name="false"
		/>

		<HomeFilters class="mb-5" />

		<div class="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-4">
			<template v-if="loadingOffers">
				<SkeletonCardOffer
					v-for="item in 3"
					:key="item"
				/>
			</template>

			<template v-else>
				<CardOffer
					v-for="offer in offers.data"
					:key="offer.id"
					:offer="offer"
				/>
				<CardAvailableOffers v-if="isShowCardAvailableOffers" />
				<CardOfferAdd v-else-if="canCreateOffer" />
				<CardSchedule v-else />
			</template>
		</div>

		<UPagination
			v-if="offers.pagination.total_pages > 1"
			v-model="offers.pagination.current_page"
			class="justify-center mt-6"
			:total="offers.pagination.total"
			:page-count="offers.pagination.per_page"
			@update:model-value="changePage"
		/>
	</UContainer>
</template>
