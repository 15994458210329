<script setup lang="ts">
const { t: $t } = useI18n()

interface Slot {
	bswo_id: number
	id: number
	name: string
	expiration: string | null
}

interface TypeSlot {
	color: string
	description: string
}

const props = defineProps<{
	offerSlot: Slot
}>()

const labels = computed(() => {
	return [
		{
			label: 'al éxito',
			color: 'blue',
			description: '',
		},
		{
			label: 'cotización',
			color: 'blue',
			description: $t(
				'Las ofertas Al éxito deben ser cotizadas por un especialista. Nuestro equipo te contactará en breve.',
			),
		},
		{
			label: 'pro',
			color: 'purple',
			description: '',
		},
		{
			label: 'staffing',
			color: 'pink',
			description: '',
		},
	]
})

const typeSlot = computed<TypeSlot>(() => {
	const name = props.offerSlot.name.toLowerCase()

	const defaultSlot = {
		color: 'gray',
		description: '',
	}

	return labels.value.find(({ label }) => name.includes(label)) || defaultSlot
})
</script>

<template>
	<UTooltip :text="typeSlot.description">
		<UBadge
			variant="soft"
			:color="typeSlot.color"
		>
			{{ offerSlot.name }}
		</UBadge>
	</UTooltip>
</template>
