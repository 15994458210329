<script setup lang="ts">
import CardPlan from '~/components/home/old/CardPlan.vue'
import CardOnboarding from '~/components/home/onboarding/CardOnboarding.vue'
import CardSchedule from '~/components/home/old/CardSchedule.vue'
import CardOffers from '~/components/home/old/CardOffers.vue'

const { $subscription } = useNuxtApp()
</script>

<template>
	<div class="flex-1 w-full py-4">
		<UContainer>
			<div class="grid xl:[grid-template-columns:repeat(18,minmax(0,1fr))] gap-5">
				<div class="xl:col-span-6 basis-4/12">
					<CardPlan />
				</div>

				<div class="xl:col-span-12 row-span-4 xl:pl-4">
					<CardOffers />
				</div>

				<CardOnboarding />

				<div
					v-if="!$subscription.isSubscriptionPro"
					class="xl:col-span-6"
				>
					<CardSchedule />
				</div>
			</div>
		</UContainer>
	</div>
</template>

<style scoped></style>
