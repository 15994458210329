<script lang="ts" setup>
const route = useRoute()
const { t: $t } = useI18n()
const localePath = useLocalePath()

const links = computed(() => {
	return [
		{
			label: $t('Todas mis ofertas'),
			to: { query: { status_slugs: undefined } },
			exact: true,
		},
		{
			label: $t('Activas'),
			to: { query: { status_slugs: 'active' } },
			exact: true,
		},
		{
			label: $t('En revisión'),
			to: { query: { status_slugs: 'pending' } },
			exact: true,
		},
		{
			label: $t('Archivadas'),
			to: { query: { status_slugs: 'closed' } },
			exact: true,
		},
	]
})

const isActive = (active) => {
	if (!active && !route.query.status_slugs) {
		return route.path === localePath('/')
	}

	return route.query.status_slugs?.includes(active)
}
</script>

<template>
	<div>
		<UCard
			class="block md:inline-block"
			:ui="{ rounded: 'rounded-lg', body: { base: 'overflow-auto', padding: 'p-2 sm:p-2' } }"
		>
			<ul class="flex items-center gap-2 whitespace-nowrap">
				<li
					v-for="link in links"
					:key="link.label"
				>
					<ULink
						class="py-1.5 px-2.5 block rounded-lg text-sm"
						:class="{ 'bg-primary-500 text-white': isActive(link.to.query.status_slugs) }"
						:to="link.to"
					>
						{{ link.label }}
					</ULink>
				</li>
			</ul>
		</UCard>
	</div>
</template>

<style scoped></style>
