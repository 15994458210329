<script setup lang="ts">
import { useToast } from 'vue-toastification'
import { useScheduleDemo } from '~/composables/useCalendly'
import { SQUAD_03_20240812_PROMOTIONAL_CODES } from '~/config/featureFlags'
import ModalToReceiveCall from '~/components/search-tool/ModalToReceiveCall.vue'

const toast = useToast()
const { $tracking } = useNuxtApp()
const { urlCalendly, settingCalendly, goToScheduled, calendlyFullLoad } = useScheduleDemo()
const showModalToReceiveCall = ref(false)
const { t: $t, locale } = useI18n()
const vwoAgendaCall = ref(false)
const calendlyButtonRef = ref(null)

const settingUtms = settingCalendly.value({
	utmSource: 'Plataforma',
	utmMedium: 'plataforma',
	utmCampaign: 'Agenda-Performance',
	utmContent: 'custom',
	utmTerm: locale.value === 'es' ? 'boton-agendar-una-llamada' : 'boton-agendar-una-llamada-en',
})

const submitSchedule = (e) => {
	goToScheduled(e)
	$tracking.upselling.scheduleProHome()
}

const successReceiveCall = () => {
	showModalToReceiveCall.value = false
	toast.success($t('Solicitud de llamada registrada.'))
	$tracking.upselling.scheduleProHomePhone()
}

const getTypeModal = () => {
	vwoAgendaCall.value = localStorage.getItem('vwo-agenda-call') === 'modal-call'

	if (vwoAgendaCall.value) {
		showModalToReceiveCall.value = true
	} else {
		nextTick(() => {
			calendlyButtonRef.value?.click()
		})
	}

	const typeModal = vwoAgendaCall.value ? 'modal_call' : 'modal_calendly'
	$tracking.upselling.scheduleProOpened('home', typeModal)
}
</script>

<template>
	<div class="relative bg-white rounded-2xl cursor-pointer">
		<ModalToReceiveCall
			v-model="showModalToReceiveCall"
			@submit-success="successReceiveCall"
			@cancel="showModalToReceiveCall = false"
		/>

		<Calendly
			type="popup"
			:url-calendar="urlCalendly"
			:params="settingUtms"
			:close-popup-after-scheduled="true"
			@event-viewed="calendlyFullLoad"
			@scheduled="submitSchedule"
		>
			<button
				ref="calendlyButtonRef"
				class="hidden"
			>
				Open Calendly
			</button>
		</Calendly>

		<div class="h-2 w-full bg-primary-500 absolute top-0 rounded-t-2xl" />
		<div
			class="pt-6 sm:pt-10 space-y-4 sm:space-y-7"
			@click="getTypeModal"
		>
			<div class="space-y-6 sm:space-y-9 px-6 sm:px-9">
				<div class="flex items-center gap-6">
					<span class="text-amber-400 flex-none text-3xl smm:text-4.8xl">
						<UIcon name="bi:stars" />
					</span>
					<div>
						<h2 class="flex-1 text-lg sm:text-2.6xl font-semibold">
							{{ $t('Agenda tu consultoría en reclutamiento') }}
						</h2>
						<p
							v-if="$hasFeature(SQUAD_03_20240812_PROMOTIONAL_CODES)"
							class="text-lg font-bold"
						>
							{{ $t('Y te regalamos') }} <span class="text-primary-500">$100 USD</span>
							{{ $t('al adquirir tu plan PRO') }}
							<UPopover
								class="text-lg text-purple-500 inline-flex"
								mode="hover"
								:popper="{ adaptive: true }"
							>
								<template #panel>
									<div class="p-2">
										<p class="text-neutral-500 text-xs">
											{{ $t('* El código te será asignado durante la llamada con tu especialista.') }}
										</p>
										<p class="text-neutral-500 text-xs">
											{{ $t('* Válido solo para tu primera suscripción, aplican términos y condiciones.') }}
										</p>
									</div>
								</template>
								<UIcon name="i-heroicons-exclamation-circle" />
							</UPopover>
						</p>
					</div>
				</div>
				<div class="flex justify-center xl:justify-end">
					<img
						:src="`${$config.public.cdn}/calendar_reference_1718678113119.png`"
						alt="Calendar"
						width="50"
						height="50"
						class="w-auto h-12 smm:h-14 xl:h-auto 2xl:h-12 5xl:h-14"
					/>
				</div>
			</div>
			<div class="flex sm:gap-3">
				<div class="w-14 hidden sm:flex">
					<div
						:style="{
							'background-image': `url(${$config.public.cdn}/schedule_time_1718684325429.png)`,
						}"
						class="bg-cover xl:bg-contain bg-no-repeat bg-top-left h-full w-full"
					/>
				</div>
				<div class="flex-1">
					<div class="pl-4 sm:pl-5 pr-4 sm:pr-9 mb-2">
						<div class="bg-[#EFF5F9] rounded-2xl py-4 px-6 flex">
							<p class="text-sm">
								{{
									$t(
										'Reserva un espacio con un especialista, asegura que tu vacante es ideal en el mercado y consigue los candidatos calificados con',
									)
								}}
								<span>
									<img
										:src="`${$config.public.cdn}/talently_pro_1718685798421.png`"
										alt="Talently Pro"
										width="50"
										height="50"
										class="h-6 w-auto inline-block"
									/>
								</span>
							</p>
						</div>
					</div>
					<div class="ml-auto mb-6 sm:mb-9 pl-2.5 sm:pl-0">
						<div class="relative right-0">
							<div class="bg-primary-700 h-1 w-full" />
							<div class="bg-primary-700 h-2.5 w-2.5 rounded-full absolute -left-1 -top-1" />
						</div>
					</div>
					<div class="space-y-18 smm:space-y-9 pl-4 sm:pl-5 pr-4 sm:pr-9 relative overflow-hidden rounded-b-2xl">
						<button class="group bg-primary-500 text-white px-4.5 py-2.5 rounded-2xl w-full">
							<div class="flex gap-2.5 xl:gap-0 2xl:gap-2.5">
								<div class="bg-primary-700 rounded-full w-12 h-12 flex xl:hidden 2xl:flex justify-center items-center">
									<UIcon
										name="i-ic-baseline-calendar-month"
										class="size-6"
									/>
								</div>
								<div class="flex flex-col items-start space-y-1.5">
									<span class="underline text-base">
										{{ $t('Agenda una llamada') }}
									</span>
									<span class="text-xs">
										{{ $t('30 min') }}
									</span>
								</div>
							</div>
						</button>

						<div class="bg-[#FBEFF9] rounded-t-3xl w-full h-14" />
						<img
							:src="`${$config.public.cdn}/woman_use_computer_1718683535278.png`"
							alt="Woman use computer"
							width="50"
							height="50"
							class="w-auto h-40 xl:h-32 5xl:h-40 absolute -bottom-3 -right-1"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped></style>
